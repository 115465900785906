import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Referrer} from "../Models/Referrer";
import {Observable} from "rxjs";
import {EnrollCustomerRequest} from "../Models/EnrollCustomerRequest";
import {EnrollCustomerResponse} from "../Models/EnrollCustomerResponse";
import {OrderCalculationRequest} from "../Models/OrderCalculationRequest";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) {
  }

  getUserIP() {
   var url = "http://ip-api.com/json";
    return this.http.get(url);
  }

  calculateOrder(order: OrderCalculationRequest) {
    return this.http.post(environment.API_ROOT + '/Orders/Calculate', order);
  }

  checkReferrer(code: string, ip: string) {
    return this.http.post<Referrer>(environment.API_ROOT+'/Utilities/CheckReferrer', {WebAlias: code, ClientIPAddress: ip});
  }

  validateWebAlias(code: string) {
    return this.http.post<Referrer>(environment.API_ROOT+'/Utilities/Webalias/Validate', {WebAlias: code});
  }

  getRegions(country: string) {
    return this.http.get<string>(environment.API_ROOT+'/Utilities/Country/Regions/'+country);
  }

  enrollCustomer(enrollmentRequest: EnrollCustomerRequest) {
   /* enrollmentRequest.orderDetails.forEach( (x)=> {
      if (x.itemCode==='CherryPop-1') {
        enrollmentRequest.markOrderAsShipped = true;
      }
    });*/

    return this.http.post(environment.API_ROOT+'/Orders/EnrollCustomer', enrollmentRequest);

  }
}
