import { Component, OnInit } from '@angular/core';
import {
  IState, selectGroupMembers, selectItemObject, selectSelectedItemObject,
  selectItemQuantity, selectAutoOrderQuantity
} from "../../reducers/index";
import { Store, select } from '@ngrx/store';
import {ItemObject} from "../../Models/ItemObject";
import {Observable} from "rxjs";
import {IItemObject, IOrder} from "../../Models/interfaces";
import {ReferrerActions} from "../../actions/referrer.actions";
import {DecrementQuantity, ItemActions} from "../../actions/item.actions";
import {OrderActions} from "../../actions/order.actions";
import {
  selectCalculatedOrder, selectCalculatedAutoship, selectShipMethods,
  IShipMethod
} from "../../reducers/orderReducer";

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  public pd: any;
  item$: Observable<IItemObject>;
  selectedItem$: Observable<IItemObject>;
  groupMembers$: Observable<Array<IItemObject>>;
  itemQuantity$: Observable<number>;
  autoOrderQuantity$: Observable<number>;
  calculatedOrder$: Observable<IOrder>;
  calculatedAutoship$: Observable<IOrder>;
  shipMethods$: Observable<Array<IShipMethod>>;
  referrer$: Observable<any>;
  public disableAutoorder: boolean;
  public hideWhatArePeopleSaying: boolean;

  constructor(private store: Store<IState>) {
    this.item$ = store.pipe(select(selectItemObject));
    this.selectedItem$ = store.pipe(select(selectSelectedItemObject));
    this.groupMembers$ = store.pipe(select(selectGroupMembers));
    this.itemQuantity$ = store.pipe(select(selectItemQuantity));
    this.autoOrderQuantity$ = store.pipe(select(selectAutoOrderQuantity));
    this.calculatedOrder$ = store.pipe(select(selectCalculatedOrder));
    this.calculatedAutoship$ = store.pipe(select(selectCalculatedAutoship));
    this.shipMethods$ = store.pipe(select(selectShipMethods));
    this.referrer$ = store.pipe(select(s=>s.referrerState.referrer));
    this.pd = {
     /* itemSelected: {
        largeImageName: '19_0502_Cart_Entourage_1oz_800x800_SGC.jpg',
        itemDescription: 'Evolv Entourage2 1oz Bottle',
        priceRetail: 81.99,
        selectedPrice: 73.99
      }*/
    }
    store.pipe(select(selectSelectedItemObject))
      .subscribe( (data) => {
        if (data.itemCode === 'CherryPop-1') {
         this.disableAutoorder = true;
         this.hideWhatArePeopleSaying = true;
        } else {
          this.disableAutoorder = false;
          this.hideWhatArePeopleSaying = false;
        }
        if (data.itemCode === 'GlobalEnroll' || data.itemCode==='USEnroll' || data.itemCode=='CAEnroll') {
          this.disableAutoorder = true;
          this.hideWhatArePeopleSaying = true;
        }
      });
  }
  onChange(value) {
    console.log('CHANGED VALUE: ', value);
    this.store.dispatch({type: ItemActions.UpdateSelectedItem, selectedItem: value});
  }
  increment() {
    this.store.dispatch({type: ItemActions.IncrementQuantity});
  }
  incrementAutoship() {
    this.store.dispatch({type: ItemActions.IncrementAutoshipQuantity});
  }
  decrement() {
    this.store.dispatch({type: ItemActions.DecrementQuantity});
  }
  decrementAutoship() {
    this.store.dispatch({type: ItemActions.DecrementAutoshipQuantity});
  }


  onShipMethodChange(value) {
    // this.store.dispatch({})
  }

  ngOnInit() {

  }



}
