/**
 * Created by nick on 7/10/2019.
 */
import {ICustomer} from "../Models/interfaces";
import {Customer} from "../Models/Customer";
import {createReducer, on} from "@ngrx/store";
import {
  UpdateCustomer, UpdateCustomerFormValue, EnrollmentSuccess, UpdateRegions,
  GetRegionsSuccess, SetWebAliasValidated
} from "../actions/customer.actions";
import {FormGroupState, createFormGroupState, onNgrxForms, wrapReducerWithFormStateUpdate} from "ngrx-forms";
import { updateGroup, validate } from 'ngrx-forms';
import {required, greaterThanOrEqualTo, email, requiredTrue} from 'ngrx-forms/validation';
import {EnrollmentSuccessful} from "../Models/EnrollmentSuccessful";
import { IRegionState, IRegion} from "./index";

export interface ICustomerState {
  customer: ICustomer;
  customerForm: FormGroupState<ICustomer>;
  enrollmentSuccess: EnrollmentSuccessful;
}

export const FORM_ID='customer-information-form';

let initialCustomer = new Customer();

const initialFormState = createFormGroupState<ICustomer>( FORM_ID,
  initialCustomer);

export interface RootForm {
  customer: ICustomer;
}

export var validateCustomer = updateGroup<ICustomer>({
    firstName: validate(required),
    lastName: validate(required),
    email: validate(required, email),
    confirmEmail: validate(required, email),
    mailAddress1: validate(required),
    mailCity: validate(required),
    mailState: validate(required),
    mailZip: validate(required),
    mailCountry: validate(required),
    mainAddress1: validate(required),
    mainCity: validate(required),
    mainState: validate(required),
    mainZip: validate(required),
    mainCountry: validate(required),
    ccName: validate(required),
    ccNumber: validate(required),
    ccCVV: validate(required),
    ccExpireMonth: validate(required),
    ccExpireYear: validate(required),
    subscribeEmail: validate(requiredTrue)
  }
);

export const initialCustomerState:ICustomerState = {
  customer: new Customer(),
  customerForm: initialFormState,
  enrollmentSuccess: undefined
}

const rawCustomerReducer = createReducer(
  initialCustomerState,
  onNgrxForms(),
  on(UpdateCustomer, (state, action) => {
    console.log('In Reducer: ', action.customer);
    return {...state, customer: action.customer, goToEnrollment: true}
  }),
  on(EnrollmentSuccess, (state, action) => {
    console.log('ENROLLMENT SUCCESS: ', action.enrollmentSuccess);
    return {...state, enrollmentSuccess: action.enrollmentSuccess}
  }),
  on(SetWebAliasValidated, (state, action) => {
    console.log('In SetWebAliasValidated: ', {...state.customer, webAliasValidated: action.webAliasValidated});
    return {...state, customer: {...state.customer, webAliasValidated: action.webAliasValidated}}
  })
)

export const customerReducer =  wrapReducerWithFormStateUpdate(
  rawCustomerReducer,
  // point to the form state to update
  s => s.customerForm,
  // this function is always called after the reducer
  validateCustomer,
);

export const initialRegionState:IRegionState = {
  countryCode: "",
  regions: new Array<IRegion>(),
}

export const regionReducer = createReducer(
  initialRegionState,
  on(UpdateRegions, (state, action) => {
    return {...state, regions: action.regions}
  }),
  on(GetRegionsSuccess, (state, action) => {
    console.log('State for Regions: ', typeof(action.regions));
    return {...state, regions: action.regions, countryCode: action.countryCode}
  }),
)
