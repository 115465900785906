import {ICustomer} from "./interfaces";
/**
 * Created by nick on 7/10/2019.
 */
export class Customer implements ICustomer {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  company: string;
  phone: string;
  mobilePhone: string;
  mailAddress1: string;
  mailAddress2: string;
  mailCity: string;
  mailState: string;
  mailZip: string;
  mailCountry: string;
  mainAddress1: string;
  mainAddress2: string;
  mainCity: string;
  mainState: string;
  mainZip: string;
  mainCountry: string;
  billingFirstName: string;
  billingLastName: string;
  billingCompany: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  couponCode: string;
  subscribeEmail: boolean;
  ccNumber: string;
  ccType: string;
  ccCVV: string;
  ccName: string;
  ccExpireMonth: string;
  ccExpireYear: string;
  noClaims: boolean;
  policiesAgreement: boolean;
  shippingBillingSame: boolean;
  webAlias: string;
  password: string;

  distributorAgreement: boolean;
  sbpRewardsAgreement: boolean;
  webAliasValidated: boolean;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.confirmEmail = '';
    this.company = '';
    this.mobilePhone = '';
    this.subscribeEmail=false;
    this.mailAddress1 = '';
    this.mailAddress2 = '';
    this.mailCity = '';
    this.mailState = '';
    this.mailZip = '';
    this.mailCountry = '';
    this.mainAddress1 = '';
    this.mainAddress2 = '';
    this.mainCity = '';
    this.mainState = '';
    this.mainZip = '';
    this.mainCountry = '';
    this.ccType = '2';
    this.ccNumber = '';
    this.ccCVV = '';
    this.ccName = '';
    this.ccExpireMonth = '';
    this.ccExpireYear = '';
    //is.noClaims = false;
    this.policiesAgreement = false;
    this.shippingBillingSame = false;
    this.webAlias = '';
    this.password = '';
    this.noClaims = false;
    this.distributorAgreement = false;
    this.sbpRewardsAgreement = false;
  }
}
