import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {Store, select} from "@ngrx/store";
import {switchMap} from 'rxjs/operators';
import {
  IState, selectSelectedItemObject, selectItemObject, selectRegionState,
  selectSecondItemObject
} from "../reducers/index";
import {take} from "rxjs/internal/operators";
import {ItemActions} from "../actions/item.actions";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public cherryPopItem: boolean;
  public countrySelected: boolean;
  state$: Observable<any>;

  constructor(private store: Store<IState>) {
    this.countrySelected = false;
    this.state$ = this.store.select(s => s);

    // Once we load the regions, we know we can proceed
    //
    this.state$.subscribe(data => {
      if (data.regionState && data.regionState.regions && data.regionState.regions.length > 0) {
        this.countrySelected = true;
      }
    });
    store.select(selectItemObject).subscribe( (itemData) => {
        console.log('Item Selected: ', itemData);
    })
    store.select(selectRegionState).subscribe((regionData) => {
      if (regionData.countryCode) {
        store.pipe(select(selectItemObject)).pipe(take(1)).subscribe(itemData => {
          console.log('Region Item Data: ', regionData, itemData);
          this.store.dispatch({
            type: ItemActions.LoadItem,
            itemCode: itemData.itemCode,
            kit: false,
            countryCode: regionData.countryCode
          })
          console.log('Done Dispatching');
        })
        store.pipe(select(selectSecondItemObject)).pipe(take(1)).subscribe(itemData => {
          console.log('Region Second Item Data: ', regionData, itemData);
          this.store.dispatch({
            type: ItemActions.LoadSecondItem,
            itemCode: itemData.itemCode,
            kit: false,
            countryCode: regionData.countryCode
          })
          console.log('Done Dispatching');
        })
      }
    });
    store.pipe(select(selectItemObject))
      .subscribe((data) => {
        if (data.itemCode === 'CherryPop-1') {
          this.cherryPopItem = true;
        } else if (data.itemCode === 'GRP_HopePopsCases_US') {
          this.cherryPopItem = true;
        } else {
          this.cherryPopItem = false;
        }
      });
  }

  ngOnInit() {
  }

}
